<template>
  <div>
    <div class="row bg-teal">
      <div class="col-6 q-pa-sm border-right row items-center justify-between">
        <h5 class="q-ma-none text-subtitle1">
          <strong>{{ $t('Products on hands') }}</strong>
        </h5>

        <q-space />

        <div class="q-mr-sm">
          <q-btn
            color="dark"
            text-color="white"
            :label="$t('Add')"
            size="sm"
            no-caps
            unelevated
            @click="handleOfferCreate"
          />
        </div>

        <div class="q-mr-sm">
          <q-btn
            color="dark"
            text-color="white"
            :label="offersFilterTitle.title"
            size="sm"
            no-caps
            unelevated
            @click="handleOpenOffersFilters"
          />
        </div>

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Import')"
          size="sm"
          no-caps
          unelevated
          @click="openAddOffers"
        />
      </div>

      <div class="col-6 q-pa-sm row items-center">
        <h5 class="q-ma-none text-subtitle1">
          <strong>{{ $t('Expected Products') }}</strong>
        </h5>

        <q-space />

        <div class="q-mr-sm">
          <q-btn
            color="dark"
            text-color="white"
            :label="expectedOffersFilterTitle.title"
            size="sm"
            no-caps
            unelevated
            @click="handleOpenExpectedOffersFilters"
          />
        </div>

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Confirm All')"
          size="sm"
          no-caps
          unelevated
          @click="handleConfirmAll"
        />
      </div>
    </div>

    <filters-collapse
      :is-open="isOpenOffersFilter"
      :options="{
        defaultFilter: offersQuery.filter,
        fields: activatedOffersFields,
        values: {
          states: offersStates,
          types: offersTypes
        }
      }"
      @submit="handleOffersFiltersSubmit"
      @close="handleCloseOffersFilters"
    />

    <filters-collapse
      :is-open="isOpenExpectedOffersFilter"
      :options="{
        defaultFilter: expectedOffersQuery.filter,
        fields: activatedExpectedOffersFields,
        values: {
          states: expectedOffersStates
        }
      }"
      @submit="handleExpectedOffersFiltersSubmit"
      @close="handleCloseExpectedOffersFilters"
    />

    <div class="row">
      <div class="col-6">
        <div
          v-if="offersLoading"
          class="row items-center justify-center full-width"
          style="min-height: 350px"
        >
          <q-spinner
            color="primary"
            size="3em"
          />
        </div>

        <q-list
          v-else
          bordered
          separator
        >
          <q-item
            v-for="item in offers"
            :key="item.id"
            v-ripple
            clickable
            style="min-height: 186px;"
            @dblclick="handleOfferClick(item)"
          >
            <q-item-section thumbnail>
              <img
                style="object-fit: contain;"
                :src="item.image || fallbackImage"
                :alt="item.name"
                @error="onImageLoadFailure"
              >
            </q-item-section>

            <q-item-section>
              <h6 class="q-ma-none border-bottom q-pb-xs text-subtitle1">
                {{ `${item.name} (${item.id}/${$moment(item.created).format(appOptions.formats.date)})` }}
              </h6>

              <div>
                <p class="q-my-xs">
                  {{ `${$t('Store')}: ${item._embedded.shop.name} (${item._embedded.shop.id})` }}
                </p>

                <p class="q-my-xs">
                  {{ `${$t('Sku')}: ${item.sku || $t('No sku')}` }}
                </p>

                <p class="q-my-xs">
                  {{ `${$t('Type')}: ${item.type}` }}
                </p>

                <items-table :items="item.items || []" />
              </div>
            </q-item-section>
          </q-item>
        </q-list>

        <div
          v-if="!offersLoading && offers.length <= 0"
          class="row items-center justify-center text-center full-width"
          style="min-height: 350px"
        >
          <strong>{{ $t('No offers') }}</strong>
        </div>

        <table-footer
          :page="offersPage"
          :total="offersItemsNumber"
          @per-page-change="onPerPageChangeOffers"
          @page-change="onPageChangeOffers"
        />
      </div>

      <div class="col-6">
        <div
          v-if="expectedOffersLoading"
          class="row items-center justify-center full-width"
          style="min-height: 350px"
        >
          <q-spinner
            color="primary"
            size="3em"
          />
        </div>

        <q-list
          v-else
          bordered
          separator
        >
          <q-item
            v-for="item in expectedOffers"
            :key="item.id"
            v-ripple
            clickable
            style="min-height: 186px;"
            @dblclick="handleExpectedOfferClick(item)"
          >
            <q-item-section thumbnail>
              <img
                style="object-fit: contain;"
                :src="item.raw.image || fallbackImage"
                @error="onImageLoadFailure"
              >
            </q-item-section>

            <q-item-section>
              <h6
                v-if="item.raw.offerData"
                class="q-ma-none border-bottom q-pb-xs text-subtitle1"
              >
                {{ `${item.raw.offerData.name} (${item.id}/${$moment(item.created.date).format(appOptions.formats.date)})` }}
              </h6>

              <h6
                v-else
                class="q-ma-none border-bottom q-pb-xs text-subtitle1"
              >
                {{ $t('No offer') }}
              </h6>

              <div>
                <p class="q-my-xs">
                  {{ `${$t('Store')}: ${item._embedded.shop.name} (${item._embedded.shop.id})` }}
                </p>

                <p class="q-my-xs">
                  {{ `${$t('Source')}: ${item._embedded.source.name} (${item._embedded.source.id})` }}
                </p>

                <p class="q-my-xs">
                  {{ `${$t('State')}: ${item.state}` }}
                </p>
              </div>
            </q-item-section>
          </q-item>
        </q-list>

        <div
          v-if="!expectedOffersLoading && expectedOffers.length <= 0"
          class="row items-center justify-center text-center full-width"
          style="min-height: 350px"
        >
          <strong>{{ $t('No offers') }}</strong>
        </div>

        <table-footer
          :page="expectedOffersPage"
          :total="totalExpectedOffersNumber"
          @per-page-change="onPerPageChangeExpectedOffers"
          @page-change="onPageChangeExpectedOffers"
        />
      </div>
    </div>

    <offer-import-modal ref="offersImport" />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import TableFooter from '../../components/table-footer/TableFooter'
import FiltersCollapse from '../../components/filters/FilterCollapse'
import ItemsTable from '../../components/items-table/ItemsTable'
import OfferImportModal from '../../components/imports/OfferImportModal.vue'

export default {
  name: 'Offers',
  components: {
    TableFooter,
    FiltersCollapse,
    ItemsTable,
    OfferImportModal
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpenOffersFilter: false,
      isOpenExpectedOffersFilter: false,
      activatedExpectedOffersFields: [
        'state',
        'id',
        'shop'
      ],
      activatedOffersFields: [
        'id',
        'name',
        'article',
        'sku',
        'type',
        'price.to',
        'price.from',
        'extId',
        'items.to',
        'items.from',
        'state==i',
        'shop',
        'created.to',
        'created.from',
        'warehouse'
      ],
      offersTypes: [
        { id: 'simple', title: 'simple' },
        { id: 'grouped', title: 'grouped' },
        { id: 'pallet', title: 'pallet' }
      ],
      offersStates: [
        { id: 'normal', title: 'normal' },
        { id: 'inactive', title: 'inactive' }
      ],
      expectedOffersStates: [
        { id: 'normal', title: 'normal' },
        { id: 'inactive', title: 'inactive' }
      ],
      offersQuery: {
        page: 1,
        per_page: 25,
        filter: []
      },
      expectedOffersQuery: {
        page: 1,
        per_page: 25,
        filter: []
      },
      alias: [
        { name: 'i', field: 'createdByDocument' },
        { name: 'i', field: 'warehouse' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'offers',
      'offersItemsNumber',
      'offersPage',
      'expectedOffers',
      'totalExpectedOffersNumber',
      'expectedOffersPage',
      'offersLoading',
      'expectedOffersLoading',
      'appOptions'
    ]),
    offersFilterTitle () {
      return this.offersQuery.filter.filter(x => x.field !== 'state').length > 0
        ? {
          bg: 'success',
          title: this.$t('Total') + ': ' + this.offersItemsNumber
        }
        : {
          bg: 'white',
          title: this.$t('Filter')
        }
    },
    expectedOffersFilterTitle () {
      return this.expectedOffersQuery.filter.filter(x => x.field !== 'state').length > 0
        ? {
          bg: 'success',
          title: this.$t('Total') + ': ' + this.totalExpectedOffersNumber
        }
        : {
          bg: 'white',
          title: this.$t('Filter')
        }
    }
  },
  mounted () {
    this.loadOffers(this.offersQuery)
    this.loadExpectedOffers(this.expectedOffersQuery)
  },
  methods: {
    ...mapActions([
      'loadOffers',
      'loadExpectedOffers'
    ]),
    ...mapMutations([
      'setOffer'
    ]),
    openAddOffers () {
      this.$refs.offersImport.open()
    },
    handleOfferClick (item) {
      this.setOffer(item)
      this.$router.push(`/products/entity/${item.type}/${item._embedded.shop.id}/${item.id}`)
    },
    handleExpectedOfferClick () {
    },
    handleCloseExpectedOffersFilters () {
      this.isOpenExpectedOffersFilter = false
    },
    handleOpenExpectedOffersFilters () {
      this.isOpenExpectedOffersFilter = !this.isOpenExpectedOffersFilter

      if (this.isOpenExpectedOffersFilter) {
        this.handleCloseOffersFilters()
      }
    },
    handleCloseOffersFilters () {
      this.isOpenOffersFilter = false
    },
    handleOpenOffersFilters () {
      this.isOpenOffersFilter = !this.isOpenOffersFilter

      if (this.isOpenOffersFilter) {
        this.handleCloseExpectedOffersFilters()
      }
    },
    handleOffersFiltersSubmit (filter, shouldCheckForShop = true) {
      if (shouldCheckForShop) {
        const shopFilter = filter.find(({ field }) => field === 'shop')

        if (shopFilter) {
          const expectedOffersFilter = this.expectedOffersQuery.filter.filter(({ field }) => field !== 'shop')
          expectedOffersFilter.push(shopFilter)

          this.handleExpectedOffersFiltersSubmit(expectedOffersFilter, false)
        } else {
          let hasShopFilter = false

          const expectedOffersFilter = this.expectedOffersQuery.filter.filter(({ field }) => {
            if (field === 'shop') {
              hasShopFilter = true
            }

            return field !== 'shop'
          })

          if (hasShopFilter) {
            this.handleExpectedOffersFiltersSubmit(expectedOffersFilter, false)
          }
        }
      }

      this.offersQuery.filter = filter
      this.isOpenOffersFilter = false

      return this.loadOffers(this.offersQuery)
    },
    handleExpectedOffersFiltersSubmit (filter, shouldCheckForShop = true) {
      if (shouldCheckForShop) {
        const shopFilter = filter.find(({ field }) => field === 'shop')

        if (shopFilter) {
          const offersFilter = this.offersQuery.filter.filter(({ field }) => field !== 'shop')
          offersFilter.push(shopFilter)

          this.handleOffersFiltersSubmit(offersFilter, false)
        } else {
          let hasShopFilter = false

          const offersFilter = this.offersQuery.filter.filter(({ field }) => {
            if (field === 'shop') {
              hasShopFilter = true
            }

            return field !== 'shop'
          })

          if (hasShopFilter) {
            this.handleOffersFiltersSubmit(offersFilter, false)
          }
        }
      }

      this.expectedOffersQuery.filter = filter
      this.isOpenExpectedOffersFilter = false

      return this.loadExpectedOffers(this.expectedOffersQuery)
    },
    handleConfirmAll () {
      return this.$service.expectedOffer.patch([{ state: 'confirmed' }])
        .then(() => {
          const query = {
            per_page: 25,
            page: 1
          }

          this.loadExpectedOffers(query)
        })
    },
    handleOfferCreate () {
      this.$router.push('/products/entity/simple')
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    onPerPageChangeExpectedOffers ({ currentPerPage }) {
      this.expectedOffersQuery.per_page = currentPerPage

      return this.loadExpectedOffers(this.expectedOffersQuery)
    },
    onPageChangeExpectedOffers ({ currentPage }) {
      this.expectedOffersQuery.page = currentPage

      return this.loadExpectedOffers(this.expectedOffersQuery)
    },
    onPerPageChangeOffers ({ currentPerPage }) {
      this.offersQuery.per_page = currentPerPage

      return this.loadOffers(this.offersQuery)
    },
    onPageChangeOffers ({ currentPage }) {
      this.offersQuery.page = currentPage

      return this.loadOffers(this.offersQuery)
    }
  }
}
</script>
