<template>
  <q-dialog
    v-model="isOpen"
    :maximized="false"
  >
    <q-card
      class="relative"
      style="min-width: 40vh; max-width: 100vw;"
    >
      <q-card-section class="row items-center q-pb-none">
        <q-btn
          v-if="slide !== 'main'"
          text-color="dark"
          size="sm"
          icon="arrow_back"
          no-caps
          flat
          unelevated
          @click="handleBack"
        />

        <q-space />

        <q-btn
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          flat
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-carousel
        :model-value="slide"
        transition-prev="scale"
        transition-next="scale"
        swipeable
        animated
        height="100%"
        width="100%"
      >
        <q-carousel-slide
          name="main"
          class="q-pt-none"
        >
          <h4 class="q-mt-none text-center">
            {{ $t('Import products from') + ':' }}
          </h4>

          <div class="row justify-center item-center">
            <div
              v-for="item in slides"
              :key="item.id"
              class="col-4 q-px-sm"
              style="min-height: 200px; min-width: 300px;"
            >
              <q-card
                class="fit card--clickable"
                @click="handleSlide(item.id)"
              >
                <q-card-section class="row fit text-center items-center justify-center">
                  <div>
                    <q-icon
                      :name="item.icon"
                      size="2rem"
                    />

                    <h5 class="q-my-none">
                      {{ $t(item.title) }}
                    </h5>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </q-carousel-slide>

        <q-carousel-slide
          name="excel"
          style="min-width: 40vw;"
          class="q-pt-none"
        >
          <offers-import @close="close" />
        </q-carousel-slide>

        <q-carousel-slide
          name="integration"
          style="min-width: 40vw;"
        >
          <ecommerce-import :source-data="'Orderadmin\\Products\\Entity\\Product\\Offer'" @close="close" />
        </q-carousel-slide>
      </q-carousel>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import EcommerceImport from './EcommerceImport.vue'
import OffersImport from './OffersImport.vue'

export default {
  name: 'OfferImportModal',
  components: {
    OffersImport,
    EcommerceImport
  },
  data () {
    return {
      isOpen: false,
      slide: 'main',
      slides: [
        {
          id: 'excel',
          title: 'File',
          icon: 'table_view'
        },
        {
          id: 'integration',
          title: 'Ecommerce Platform',
          icon: 'local_offer'
        }
      ]
    }
  },
  methods: {
    handleSlide (slide) {
      this.slide = slide
    },
    handleBack () {
      this.slide = 'main'
    },
    open () {
      this.slide = 'main'
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    }
  }
}
</script>
