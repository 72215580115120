<template>
  <div>
    <div class="row justify-center">
      <div
        class="text-h6 text-center q-mb-sm"
        style="max-width: 500px;"
      >
        {{ $t('Create task to import product.') }}
      </div>
    </div>

    <form-builder :schema="schema" />

    <div class="text-center q-py-sm">
      <q-btn
        color="light-blue-9"
        text-color="white"
        size="md"
        :label="$t('Import')"
        no-caps
        unelevated
        :disable="!item.source"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EcommerceImport',
  emits: ['close'],
  props: {
    sourceData: {
      type: String,
      required: true,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      item: {
        extId: '',
        source: null
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Ecommerce platform'),
                field: 'source',
                value: this.item.source,
                values: this.loadedSources,
                required: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.iSource.getAll(query)
                },
                onChange: value => {
                  this.item.source = value
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.$service.iSource.getAll({ per_page: 5, page: 1, filter: [{ type: 'eq', field: 'state', value: 'active' }] })
      .then(({ items, totalItems }) => {
        if (totalItems === 1) {
          this.item.source = items[0]
        }
      })
  },
  methods: {
    save () {
      const data = {
        ...this.item,
        source: this.item.source.id,
        sourceData: {
          asyncMode: {
            [this.sourceData]: true
          }
        }
      }

      return this.$service.integrationImport.save(data)
        .then(() => {
          this.$emit('close')
        })
    }
  }
}
</script>
