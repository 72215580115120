<template>
  <div class="q-pb-sm">
    <div
      class="text-center"
      :class="items.length > 0 ? 'row items-center justify-center' : ''"
    >
      <q-btn
        :label="$t('Browse file on your computer')"
        size="md"
        :style="items.length <= 0 ? 'min-height: 5rem;' : ''"
        color="light-blue-9"
        no-caps
        @click="handleBrowse"
      />

      <div class="row items-center justify-center q-my-md">
        <div
          style="text-decoration: underline;"
          class="clickable text-dark q-pa-md"
          @click="getExampleTable"
        >
          {{ $t('Download template') }}
        </div>

        <a
          :href="$instructions.offer.import"
          class="clickable text-dark q-pa-md"
          target="blank"
        >
          {{ $t('Read instructions') }}
        </a>
      </div>

      <q-btn
        color="light-blue-9"
        text-color="white"
        size="md"
        :label="$t('Upload all products')"
        class="q-mr-sm"
        :disable="items.length <= 0"
        no-caps
        unelevated
        @click="saveAll"
      />
    </div>

    <q-file
      ref="fileInput"
      filled
      class="d-none"
      :label="$t('Pick File')"
      @update:model-value="handleTable"
    />

    <div v-if="items.length > 0">
      <q-table
        row-key="extId"
        class="full-width"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: items.length }"
        virtual-scroll
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td
              key="extId"
              :props="props"
            >
              <strong v-if="!props.row.extId">-</strong>

              <strong v-else>{{ props.row.extId }}</strong>
            </q-td>

            <q-td
              key="image"
              :props="props"
            >
              <img
                style="max-width: 80px; max-height: 80px; object-fit: contain;"
                :src="props.row.image ? props.row.image : 'assets/box_sm.png'"
                @error="onImageLoadFailure"
              >
            </q-td>

            <q-td
              key="name"
              :props="props"
            >
              <strong>{{ props.row.name }}</strong>
            </q-td>

            <q-td
              key="article"
              :props="props"
            >
              <strong>{{ props.row.article }}</strong>
            </q-td>

            <q-td
              key="sku"
              :props="props"
            >
              <strong>{{ props.row.sku }}</strong>
            </q-td>

            <q-td
              key="type"
              :props="props"
            >
              <q-select
                standout="bg-teal text-white"
                class="select--error-hint"
                :model-value="props.row.type"
                :options="typeOptions"
                :label="$t('Type')"
                :hint="types.includes(props.row.type) ? undefined : $t(`Wrong type`)"
              >
                <template v-slot:option="data">
                  <div
                    class="q-pa-sm card--clickable card--clickable-no-scale"
                    :class="data.selected ? 'bg-success' : ''"
                    @click="handleSelectType(props.row, data.opt)"
                  >
                    {{ $t(data.opt.name) }}
                  </div>
                </template>
              </q-select>
            </q-td>

            <q-td
              key="price"
              :props="props"
            >
              <strong v-if="!props.row.price">-</strong>

              <strong v-else>{{ props.row.price }}</strong>
            </q-td>

            <q-td
              key="dimensions"
              :props="props"
            >
              <span v-if="!props.row.dimensions">
                <strong>-</strong>
              </span>

              <span v-else>
                <strong>
                  {{ Object.keys(props.row.dimensions).reduce((acc, key, i) => `${acc}${i > 0 ? ',' : ''} ${key.toUpperCase()}: ${props.row.dimensions[key]}`, '') }}
                </strong>
              </span>
            </q-td>

            <q-td
              key="weight"
              :props="props"
            >
              <span v-if="!props.row.weight">
                <strong>-</strong>
              </span>

              <span v-else>
                <strong>{{ props.row.weight }}</strong>
              </span>
            </q-td>

            <q-td
              key="actions"
              :props="props"
            >
              <q-btn
                color="light-blue-9"
                size="sm"
                :label="$t('Upload')"
                @click="handleSave(props.row, props.rowIndex)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <confirm-modal ref="confirm" size="xl" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import ConfirmModal from '../confirm-modal/ConfirmModal.vue'

export default {
  name: 'OffersImport',
  emits: ['close'],
  components: {
    ConfirmModal
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      items: [],
      typeOptions: [
        { id: 'simple', name: 'Simple' },
        { id: 'configurable', name: 'Configurable' },
        { id: 'grouped', name: 'Grouped' },
        { id: 'virtual', name: 'Virtual' },
        { id: 'bundle', name: 'Bundle' }
      ],
      types: [
        'simple',
        'configurable',
        'grouped',
        'virtual',
        'bundle'
      ],
      columns: [
        {
          label: this.$t('ExtId'),
          name: 'extId',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Article'),
          name: 'article',
          align: 'left'
        },
        {
          label: this.$t('Barcode'),
          name: 'sku',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Dimensions'),
          name: 'dimensions',
          align: 'left'
        },
        {
          label: this.$t('Weight'),
          name: 'weight',
          align: 'left'
        },
        {
          label: this.$t('Actions'),
          name: 'actions',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'offers'
    ])
  },
  methods: {
    ...mapMutations([
      'setOffers',
      'addErrorNotification'
    ]),
    handleSelectType (row, type) {
      row.type = type.id
    },
    handleBrowse () {
      this.$refs.fileInput.pickFiles()
    },
    handleCloseAddOffers () {
      this.items = []
      this.$emit('close')
    },
    saveAll () {
      const wrongTypes = this.items.filter(({ type }) => !this.types.includes(type))

      this.items = this.items.map(e => {
        if (e.dimensions) {
          if (typeof e.dimensions.x === 'string') {
            e.dimensions.x = parseFloat(e.dimensions.x.replace(',', '.'));
          }
          if (typeof e.dimensions.y === 'string') {
            e.dimensions.y = parseFloat(e.dimensions.y.replace(',', '.'));
          }
          if (typeof e.dimensions.z === 'string') {
            e.dimensions.z = parseFloat(e.dimensions.z.replace(',', '.'));
          }
        }
        return e;
      });


      if (wrongTypes.length > 0) {
        this.addErrorNotification(`We found products with wrong type -> ${wrongTypes.join(', ')}, the product may have any of the following types -> ${this.types.join(', ')}`)
        return
      }

      return this.$service.imports.offer.execute(this.items, () => {})
        .then(({ results, errors }) => {
          this.items = []
          this.setOffers([
            ...results,
            ...this.offers
          ])

          if (errors.length > 0) {
            const options = {
              description: this.$t('Sorry, but we finish importing with some errors. Please check them and if it is needed connect with support.'),
              list: errors.map(text => {
                return {
                  styleClasses: 'bg-negative text-white',
                  text
                }
              })
            }

            return this.$refs.confirm.show(options)
          }
        })
    },
    getExampleTable () {
      return this.$service.imports.offer.getOfferExample()
    },
    handleTable (file) {
      if (!file) {
        return
      }

      return this.$service.imports.offer.loadFile(file)
        .then(result => {
          this.items = result
        })
    },
    handleSave (offer, index) {
      if (!this.types.includes(offer.type)) {
        this.addErrorNotification(`Product has wrong type -> ${offer.type}, the product may have any of the following types -> ${this.types.join(', ')}`)
        return
      }

      offer.price = Number(offer.price || 0).toFixed(2)

      if (!offer.extId) {
        offer.extId = Math.floor(Math.random() * (9999999999) + 1)
      }

      return this.$service.offer.save(offer)
        .then(item => {
          this.setOffers([
            item,
            ...this.offers
          ])
          this.items = this.items.filter((x, i) => i !== index)
        })
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    }
  }
}
</script>
