<template>
  <div class="row align-center justify-between q-py-xs">
    <div class="q-pa-sm">
      <q-select
        v-model="perPage"
        standout="bg-teal text-white"
        :options="options"
        dense
        :label="$t('Rows per page')"
        @input="handlePerPage"
      />
    </div>

    <div class="q-py-sm row items-center text-subtitle1">
      <strong class="q-mr-sm">{{ $t('Total') + ': ' }}</strong>

      <span>{{ total }}</span>
    </div>

    <div class="row align-center q-pr-md">
      <div class="q-pt-sm">
        <q-btn
          color="dark"
          text-color="white"
          icon="chevron_left"
          :disable="(page || 1) <= 1"
          @click="handlePageDecrease"
        />
      </div>

      <div class="q-mx-sm row items-center q-px-sm">
        <q-input
          standout="bg-teal text-white"
          :label="$t('Page')"
          :model-value="page"
          type="number"
          class="q-mr-sm"
          style="max-width: 130px;"
          dense
          @update:model-value="handlePageChange"
        />

        <span style="max-height: 20px;">
          {{ $t('of') + ' ' + maxPages }}
        </span>
      </div>

      <div class="q-pt-sm">
        <q-btn
          color="dark"
          text-color="white"
          icon="chevron_right"
          :class="page >= maxPages ? 'disabled' : ''"
          @click="handlePageIncrease"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableFooter',
  emits: ['page-change', 'per-page-change'],
  props: {
    page: {
      type: Number,
      default () {
        return 1
      }
    },
    total: {
      type: Number,
      default () {
        return 0
      }
    }
  },
  data () {
    return {
      perPage: 25,
      options: [25, 50, 100, 150, 200, 250]
    }
  },
  computed: {
    maxPages () {
      return Number.parseFloat(this.total / this.perPage).toFixed(0)
    }
  },
  methods: {
    handlePerPage (value) {
      this.perPage = value
      this.$emit('per-page-change', { currentPerPage: value })
    },
    handlePageIncrease () {
      this.$emit('page-change', { currentPage: this.page + 1 })
    },
    handlePageDecrease () {
      this.$emit('page-change', { currentPage: this.page - 1 })
    },
    handlePageChange (value) {
      this.$emit('page-change', { currentPage: value })
    }
  }
}
</script>
